
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";

export default function EditProductModal(props) {
  const { showEditProductModal, setShowEditProductModal, productsRef, productToEdit, setProductToEdit} = props;
  const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [pricePer, setPricePer] = useState("");
    const [stock, setStock] = useState(1);
    const [imageSrc, setImageSrc] = useState("");
    const [description, setDescription] = useState("");
    const [imageFile, setImageFile] = useState(null);
    const [featured, setFeatured] = useState(null);
    
    const storage = getStorage();

  function closeModal() {
    setShowEditProductModal(false);
  }

  useEffect(() => {
    if (productToEdit) {
        setName(productToEdit.name);
        setPrice(productToEdit.price);
        setPricePer(productToEdit.pricePer);
        setStock(productToEdit.stock);
        setImageSrc(productToEdit.imageSrc);
        setDescription(productToEdit.description);
        setFeatured(productToEdit.featured);

        }
    }, [productToEdit]);

  const handleEditProduct = async (event) => {
    event.preventDefault();
  
    // Check if all fields have a value
    if (name && price && pricePer && stock) {
      // Create a new product object with updated values
      const updatedProduct = {
        name: name,
        price: price,
        pricePer: pricePer,
        stock: stock,
        description: description,
        imageAlt: name,
        featured: featured,
      };
  
      try {
        if (imageFile) {
          // Upload the new image file to Firebase Storage
          const imageRef = ref(storage, "productImages/" + imageFile.name);
          await uploadBytes(imageRef, imageFile);
  
          // Get the download URL of the uploaded image
          const imageUrl = await getDownloadURL(imageRef);
  
          // Update the updated product object with the new image URL
          updatedProduct.imageSrc = imageUrl;
        }
  
        // Update the existing product in the Firestore collection
        await productsRef.doc(productToEdit.id).update(updatedProduct);
  
        // Product updated successfully
        console.log('Product updated:', updatedProduct);
  
        // Reset the form fields
        setName("");
        setPrice("");
        setPricePer("");
        setStock("");
        setImageFile(null);
        setDescription("");
        setFeatured(null);
  
        // Close the modal
        closeModal();
      } catch (error) {
        // Error occurred while updating the product or uploading the image
        console.error('Error updating product:', error);
      }
    } else {
      // At least one field is empty
      console.log('Please fill in all fields');
    }
  };
  
  
  
  

  return (
    <>
      <Transition appear show={showEditProductModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    New Product
                  </Dialog.Title>
                  <div className="mt-2  justify-center items-center text-center">
                   
                    <form>
                    <div className="grid grid-cols-4 gap-4">
                      <div className="col-span-3">
                        <label
                          htmlFor="Product Name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Product Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="Product Name"
                            id="Product Name"
                            value={name}
                            placeholder="Eggs"
                            onChange={(e) => setName(e.target.value)}
                            className="w-full sm:text-md border text-center border-gray-300 rounded-sm"
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="stock"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Stock
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="stock"
                            id="stock"
                            value={stock}
                            placeholder="12"
                            onChange={(e) => setStock(e.target.value)}
                            className=" w-full sm:text-md outline-1 text-center border border-gray-300 rounded-sm"
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="price"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Price
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="price"
                            value={price}
                            id="price"
                            placeholder="1.99"
                            onChange={(e) => setPrice(e.target.value)}
                            className=" w-full sm:text-md outline-1 text-center border border-gray-300 rounded-sm"
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="price per"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Price Per
                        </label>
                        <div className="mt-1">
                          <select
                            type="text"
                            name="price per"
                            id="price per"
                            value={pricePer}
                            placeholder="/doz /lbs /each /etc"
                            onChange={(e) => setPricePer(e.target.value)}
                            className=" w-full sm:text-md outline-1 text-center border border-gray-300 rounded-sm"
                          >
                            <option value="/doz">/doz</option>
                            <option value="/lb">/lb</option>
                            <option value="/each">/each</option>
                            <option value="/etc">/etc</option>
                            </select>
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="featured"
                          className="block text-sm font-medium text-gray-700"
                        >
                            Featured
                        </label>
                        <div className="mt-1">
                          <input
                            type="checkbox"
                            name="featured"
                            id="featured"
                            checked={featured}
                            onChange={(e) => setFeatured(e.target.checked)}
                            className=" w-full sm:text-md outline-1 text-center border border-gray-300 rounded-sm"
                          />
                        </div>
                      </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="product description"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Product Description
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="product description"
                            name="product description"
                            value={description}
                            rows="3"
                            onChange={(e) => setDescription(e.target.value)}
                            className="shadow-sm p-2 block w-full sm:text-sm border border-gray-300 rounded-sm"
                          ></textarea>
                        </div>
                      </div>
                      <div className="">
                      <label
                          htmlFor="image"
                          className="block text-sm font-medium text-gray-700"
                        >
                            Product Image
                        </label>
                        <div className="mt-1">
                          <input
                            type="file"
                            name="image"
                            id="image"
                            onChange={(e) => setImageFile(e.target.files[0])}
                            className=" w-full sm:text-md outline-1 text-center border border-gray-300 rounded-sm"
                          />
                        </div>
                      </div>
                      <div className="mt-6 flex flex-col items-center">
                        <button
                          onClick={handleEditProduct}
                          className="w-full mb-3 justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-BarnRed-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        >
                          Save Changes
                        </button>
                      </div>
                    </form>
                    <button
                      type="button"
                      className="justify-center text-gray-500 hover:text-black w-20 text-center items-center hover:underline active:sacle-95"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
