import { useEffect, useState } from "react";
import { StarIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";
import { useParams } from "react-router-dom";
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductPage(props) {
  const { productsRef, toast } = props;

  const [product, setProduct] = useState({});
  const [selectedOption, setSelectedOption] = useState(0);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const { selectedProduct } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await productsRef.doc(selectedProduct).get();
      const product = snapshot.data();
      const fetchedProduct = {
        id: snapshot.id,
        name: product.name,
        price: product.price,
        imageSrc: product.imageSrc,
        imageAlt: product.imageAlt,
        options: product.option,
        description: product.description,
        pricePer: product.pricePer,
        stock: product.stock,
      };
      setProduct(fetchedProduct);
    };
  
    if (selectedProduct) {
      fetchData();
    }
  
    return () => {
      // Clean up the subscription or any other resources if needed
    };
  }, [productsRef, setProduct, selectedProduct]);
  

  function handleQuantityChange(event) {
    setSelectedQuantity(event.target.value);
  }

  async function handleAddToCart() {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    const productToAdd = {
      productId: selectedProduct,
      quantity: selectedQuantity,
      price: product.price,
      name: product.name,
      imageSrc: product.imageSrc,
      pricePer: product.pricePer,
    };

    const existingProductIndex = cart.findIndex(
      (item) => item.productId === productToAdd.productId
    );

    if (existingProductIndex > -1) {
      cart[existingProductIndex].quantity += selectedQuantity;
    } else {
      cart.push(productToAdd);
    }

    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem("cart", JSON.stringify(cart));
        console.log("cart:", cart);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  function addToCart() {
    handleAddToCart()
      .then(() => {
        toast.success("Item added to cart", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        toast.error("Error adding item to cart: " + error.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <div className="bg-white pb-24">
      <div className="pt-6">
        <nav aria-label="Breadcrumb">
          <ol
            role="list"
            className="mx-auto flex  items-center space-x-2 px-10 md:px-16  lg:max-w-7xl lg:px-16"
          >
            <li>
              <div className="flex items-center">
                <a href={"/Shop"} className="text-sm font-medium text-gray-900">
                  {"Back to Shop"}
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <div className="md:flex pr-10 px-10 ">
          {/* Image gallery */}
          <div className="mx-auto mt-6 max-w-2xl md:px-6  lg:px-8">
            <div className=" max-w-xl max-h-35-rem overflow-hidden rounded-lg">
              <img
                src={product.imageSrc}
                alt={product.imageAlt}
                className=" object-cover object-center"
              />
            </div>
          </div>

          {/* Product info */}

          <div className="mt-4 pb-12 px-2 md:w-128">
            <h1 className=" font-bold tracking-tight text-gray-900 pb-1 text-3xl">
              {product.name}
            </h1>
            <h2 className="sr-only">Product information</h2>
            <div className="flex pb-4">
              <p className="text-3xl tracking-tight text-gray-900">
                ${product.price}
              </p>
              <p className="mt-1 ml-2 text-xl text-gray-500">
                {product.pricePer}
              </p>
            </div>
            <div>
              <h3 className="sr-only">description</h3>
              <p className="text-sm text-gray-900">{product.description}</p>
            </div>

            <form className="mt-10">
              {/* Options */}
              <div className="mt-10">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">
                    Quantity
                  </h3>
                  <a
                    href="#"
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    ?
                  </a>
                </div>

                {/* Options go here if needed */}
                <div className="flex items-center space-x-3 pt-4">
                  <button
                    className="inline-flex items-center p-1 text-sm font-medium text-gray-900 bg-gray-300 border border-gray-300 hover:bg-gray-600 rounded-full focus:outline-none active:scale-95"
                    type="button"
                    aria-label="Decrease quantity by 1"
                    onClick={() => {
                      selectedQuantity > 1 &&
                        setSelectedQuantity(selectedQuantity - 1);
                    }}
                  >
                    <span className="sr-only">Quantity button</span>
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  <div>
                    <input
                      type="number"
                      id="first_product"
                      className="w-10 appearance-none resize-none py-0 border text-center border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block bg-gray-200"
                      placeholder="qty"
                      value={selectedQuantity}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (!isNaN(value) && value >= 1) {
                          setSelectedQuantity(value);
                        } else if (value === 1 || e.target.value === "") {
                          setSelectedQuantity("");
                        } else {
                          setSelectedQuantity(1);
                        }
                      }}
                      required
                    />
                  </div>
                  <button
                    className="inline-flex items-center p-1 text-sm font-medium text-gray-900 bg-gray-300 border border-gray-300 hover:bg-gray-600 rounded-full focus:outline-none active:scale-95"
                    type="button"
                    onClick={() => {
                      setSelectedQuantity(selectedQuantity + 1);
                    }}
                  >
                    <span className="sr-only">Quantity button</span>
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <h4
                className="mt-1 text-sm text-gray-500"
                >
                  {product.stock} left in stock
                  </h4>
              </div>

              <button
                className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-BarnRed-900 px-8 py-3 text-base font-medium text-white hover:bg-BarnRed-700 focus:outline-none focus:ring-2 focus:ring-darkcream-500 active:scale-95 focus:ring-offset-2"
                onClick={(e) => {
                  e.preventDefault();
                  addToCart();
                }}
              >
                Add to bag
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

/*

option selections

<RadioGroup
                  value={selectedOption}
                  onChange={setSelectedOption}
                  className="mt-4"
                >
                  <RadioGroup.Label className="sr-only">
                    select option
                  </RadioGroup.Label>
                  <div className="flex justify-start  gap-4">
                    {product.options &&
                      product.options.map((option) => (
                        <RadioGroup.Option
                          key={option.name}
                          disabled={!option.stock}
                          className={({ active }) =>
                            classNames(
                              option.stock
                                ? "cursor-pointer bg-white text-gray-900 shadow-sm"
                                : "cursor-not-allowed bg-gray-50 text-gray-200",
                              active ? "ring-2 ring-darkcream-500" : "",
                              "group relative flex items-center justify-center rounded-md border text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none w-20 h-10"
                            )
                          }
                          value={option}
                          checked={selectedOption === option}
                        >
                          {({ active, checked }) => (
                            <>
                              <RadioGroup.Label as="span">
                                {option.name}
                              </RadioGroup.Label>
                              {option.stock ? (
                                <span
                                  className={classNames(
                                    active ? "border" : "border-2",
                                    checked
                                      ? "border-darkcream-400"
                                      : "border-transparent",
                                    "pointer-events-none absolute -inset-px rounded-md"
                                  )}
                                  aria-hidden="true"
                                />
                              ) : (
                                <span
                                  aria-hidden="true"
                                  className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                                >
                                  <svg
                                    className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="none"
                                    stroke="currentColor"
                                  >
                                    <line
                                      x1={0}
                                      y1={100}
                                      x2={100}
                                      y2={0}
                                      vectorEffect="non-scaling-stroke"
                                    />
                                  </svg>
                                </span>
                              )}
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                  </div>
                </RadioGroup>
*/
