import React from 'react'

function About() {
  return (
    <div>

        <h1>About</h1>
    </div>
  )
}

export default About