import React, { useEffect, useState } from "react";
import NavBar from "./components/NavBar/NavBar";
import Cart from "./components/Cart/Cart";
import Home from "./components/Home/Home";
import ContactModal from "./components/Contact/ContactModal";
import { Route, Routes, Navigate } from "react-router-dom";
import About from "./components/About/About";
import Blog from "./components/Blog/Blog";
import Shop from "./components/Shop/Shop";
import ProductPage from "./components/ProductPage/productPage";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import NewSignIn from "./components/Signin/NewSignIn";
import { useAuthState } from "react-firebase-hooks/auth";
import "./index.css";
import Alert from "./components/Alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./components/Admin/Dashboard";
import BlogPosts from "./components/Admin/Pages/BlogPosts";
import Orders from "./components/Admin/Pages/Orders";
import ProductList from "./components/Admin/Pages/ProductList";
import UsersList from "./components/Admin/Pages/UsersList";
import Calendar from "./components/Admin/Pages/Calendar";
import { getStorage, ref, uploadBytes, getDownloadURL, } from "firebase/storage";



firebase.initializeApp({
  apiKey: "AIzaSyCJMC-r3r2-VJ_UAcIdFgE3_tLFP30TrB8",
  authDomain: "littleredshed-38356.firebaseapp.com",
  projectId: "littleredshed-38356",
  storageBucket: "littleredshed-38356.appspot.com",
  messagingSenderId: "847550630570",
  appId: "1:847550630570:web:47c78dc64c40e3acb41ed3",
  measurementId: "G-QB29LZ6R98",
});

const auth = firebase.auth();
const firestore = firebase.firestore();
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const blogRef = firestore.collection("blog");
const productsRef = firestore.collection("products");
const usersRef = firestore.collection("Users");
const ordersRef = firestore.collection("orders")
const storage = getStorage();

function App() {
  const [showCart, setShowCart] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [user] = useAuthState(auth);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    console.log("isSidebarOpen:", isSidebarOpen);
  };
  if (user) {
    // User is signed in.
    localStorage.setItem("isUserSignedIn", JSON.stringify(true));
  } else {
    // No user is signed in.
    localStorage.setItem("isUserSignedIn", JSON.stringify(false));
  }

  const isUserSignedIn = JSON.parse(localStorage.getItem("isUserSignedIn"));

  useEffect(() => {
    const fetchUserPermissions = async () => {
      const userId = user && user.uid;
      if (user) {
        try {
          const doc = await usersRef.doc(userId).get();
          if (doc.exists) {
            const permissions = doc.data().permissions;
            if (permissions.includes("admin")) {
              setIsUserAdmin(true);
            } else {
              setIsUserAdmin(false);
            }
            console.log("Current data:", doc.data());
            console.log("isUserAdmin:", isUserAdmin);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.log("Error retrieving document:", error);
        }
      }
    };

    fetchUserPermissions();
  }, [user]);
  /* user longin handling */
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth
      .signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        const profile = result.additionalUserInfo.profile;
        const userId = user.uid;
        const userEmail = user.email;
        const userFirstName = profile.given_name;
        const userLastName = profile.family_name;
        usersRef
          .doc(userId)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              saveUserToDatabase(
                userId,
                userEmail,
                userFirstName,
                userLastName
              );
            } else {
              console.log("User already exists");
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function sendVerificationEmail() {
    const user = auth.currentUser;
    try {
      await user.sendEmailVerification();
      triggerAlert(
        "Verification Email Sent!",
        "Please check your email for a verification link."
      );
      console.log("Email sent");
    } catch (error) {
      console.log(error);
    }
  }

  const sendPasswordResetEmail = (email) => {
    auth.sendPasswordResetEmail(email);
  };

  const saveUserToDatabase = (userId, newEmail, firstName, lastName) => {
    usersRef
      .doc(userId)
      .set({
        email: newEmail,
        firstName: firstName,
        lastName: lastName,
        permissions: [],
      })
      .then(() => {
        usersRef.collection("purchaseHistory").doc(userId).set();
      });
  };

  function SignOut() {
  
    auth.currentUser &&
      auth
        .signOut()
        .then(() => {
          toast.success("Signed out successfully! page will refresh in 3 seconds", {
            position: "top-center",
            autoClose: 3000,
          });
          setTimeout(() => {
            window.location.reload();
          }
          , 3500);
        })
        .catch((error) => {
          console.log("An error occurred during sign-out:", error);
        });
      }

  /* end user login handling */

  function triggerAlert(alertTitle, alertMessage) {
    setAlertTitle(alertTitle);
    setAlertMessage(alertMessage);
    setAlert(true);
  }

  const commonContent = (
    <div className="pt-16 h-full">
    <Routes>
      <Route
        path="/"
        element={
          <div>
            <Home productsRef={productsRef} />
          </div>
        }
      />
      <Route
        path="/SignIn"
        element={
          isUserSignedIn ? (
            <Navigate to="/" />
          ) : (
            <div>
              <NewSignIn
                signInWithGoogle={signInWithGoogle}
                sendPasswordResetEmail={sendPasswordResetEmail}
                sendVerificationEmail={sendVerificationEmail}
                auth={auth}
                saveUserToDatabase={saveUserToDatabase}
              />
            </div>
          )
        }
      />

      <Route
        path="/about"
        element={
          <div>
            <About />
          </div>
        }
      />
      <Route
        path="/Shop"
        element={
          <div>
            <Shop productsRef={productsRef} />
          </div>
        }
      />
      <Route
        path="/Blog"
        element={
          <div>
            <Blog blogRef={blogRef} />
          </div>
        }
      />
      <Route
        path="/product/:selectedProduct"
        element={
          <div>
            <ProductPage productsRef={productsRef} toast={toast} />
          </div>
        }
      />
                  <Route
              path="/Dashboard/products"
              element={
                <ProductList
                isUserAdmin={isUserAdmin}
                productsRef={productsRef}
                toast={toast}
                />
              }
            />
            <Route
              path="/Dashboard/posts"
              element={
                <BlogPosts
                isUserAdmin={isUserAdmin}
                blogRef={blogRef}
                />
              }
            />
            <Route
              path="/Dashboard/users"
              element={
                <UsersList
                isUserAdmin={isUserAdmin}
                usersRef={usersRef}
                />
              }
            />
            <Route
              path="/Dashboard/orders"
              element={
                <Orders
                isUserAdmin={isUserAdmin}
                ordersRef={ordersRef}
                />
              }
            />
                        <Route
              path="/Dashboard/Calendar"
              element={
                <Calendar
                isUserAdmin={isUserAdmin}
                ordersRef={ordersRef}
                />
              }
            />
    </Routes>
    <footer className="bottom-0 left-0 z-50 w-full p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
        © 2023{" "}
        <a href="https://flowbite.com/" className="hover:underline">
          Little Red Shed™
        </a>
        . All Rights Reserved.
      </span>
      <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
          <button
            onClick={() => setShowContactModal(true)}
            className="hover:underline"
          >
            Contact
          </button>
        </li>
      </ul>
    </footer>
  </div>
  );

  return (
    <div className="w-full h-full">
      <div className="h-full">
        <NavBar
          isUserSignedIn={isUserSignedIn}
          isUserAdmin={isUserAdmin}
          setShowCart={setShowCart}
          setShowContactModal={setShowContactModal}
          setShowLogin={setShowLogin}
          SignOut={SignOut}
          toggleSidebar={toggleSidebar}
        />
        <Cart showCart={showCart} setShowCart={setShowCart} toast={toast} />
        <Alert
          triggerOpen={alert}
          setTriggerOpen={setAlert}
          alertTitle={alertTitle}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
          setAlertTitle={setAlertTitle}
        />
        {isUserAdmin ? (
          <div className="h-full">
            <Dashboard
            productsRef={productsRef}
            blogRef={blogRef}
            usersRef={usersRef}
            toast={toast}
            isSidebarOpen={isSidebarOpen}
          />
          <div className={isSidebarOpen ? "sm:ml-36" : "" +'h-full'}>
          {commonContent}
          </div>
          </div>
        ):(
          commonContent
        )}


        <ContactModal
          showContactModal={showContactModal}
          setShowContactModal={setShowContactModal}
        />
        <ToastContainer />
      </div>
    </div>
  );
}

export default App;
