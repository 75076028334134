import React from "react";
import { useNavigate } from "react-router-dom";

function Orders(props) {
  const { isUserAdmin, oredersRef } = props;

  const navigate = useNavigate();
  /* check if user is admin, if not redirect to home page */
  if (!isUserAdmin) {
    navigate("/");
  }

  return (
    <div className="w-screen">
      {isUserAdmin ? <h1> you have access</h1> : <h1> you dont have access</h1>}
    </div>
  );
}

export default Orders;
