import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";

const navigation = [
  { name: "Shop", href: "/Shop", current: false },
  /*
  { name: "Blog", href: "/Blog", current: false },
  { name: "About", href: "/About", current: true },
  */
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar(props) {
  const {
    SignOut,
    setShowCart,
    setShowContactModal,
    isUserSignedIn,
    toggleSidebar,
    isUserAdmin,
  } = props;

  const location = useLocation();

  const handleSidebarToggle = () => {
    toggleSidebar();
  };

  return (
    <Disclosure as="nav" className=" shadow-lg fixed z-50 w-full bg-cream">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-800 hover:bg-BarnRed-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
                <span
                  onClick={handleSidebarToggle}
                  className="inline-flex items-center justify-center rounded-md p-2 text-gray-800 active:bg-BarnRed-900 active:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white active:scale-95"
                >
                  <WrenchScrewdriverIcon className="block h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link to={"/"} className="text-gray-800 font-bold text-xl">
                    <img className="h-14 w-auto" src="/words_Logo.png"></img>
                  </Link>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        location.pathname === item.href
                          ? " underline text-black"
                          : "text-gray-800 hover:text-black hover:scale-105 active:scale-95",
                        "px-2 py-1 rounded-sm tracking-wide font-medium "
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  ))}
                  <a
                    onClick={() => setShowContactModal(true)}
                    className="text-gray-800 cursor-pointer hover:text-black hover:scale-105 active:scale-95 px-2 py-1 rounded-sm tracking-wide font-medium "
                  >
                    Contact
                  </a>
                  {isUserAdmin && (
                    <button
                      onClick={handleSidebarToggle}
                      className="text-gray-800 cursor-pointer hover:text-black hover:scale-105 active:scale-95 px-2 py-1 rounded-sm tracking-wide font-medium "
                      >
                      Admin Panel
                    </button>
                  )}
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  id="cartButton"
                  type="button"
                  className="cartCount rounded-full bg-cream p-1 text-gray-800 hover:text-black hover:scale-105 active:scale-95 focus:outline-none hover:ring-2 hover:ring-offset-1 hover:ring-darkcream-300"
                  onClick={() => setShowCart(true)}
                >
                  <span className="sr-only">View Cart</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                    />
                  </svg>
                </button>

                {isUserSignedIn ? (
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full p-0 bg-cream text-gray-800 hover:text-black focus:outline-none hover:ring-2 hover:ring-offset-1 hover:ring-darkcream-300 hover:scale-105 active:scale-95">
                        <span className="sr-only">Open user menu</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={SignOut}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <div className="flex ">
                    <Link
                      to="/SignIn"
                      className="bg-black text-white px-2 py-1 ml-3 rounded tracking-wide text-sm font-medium hover:bg-gray-800 hover:scale-105 active:scale-95"
                    >
                      Sign in
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-700 text-white"
                      : "text-black hover:bg-BarnRed-900 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              <a
                onClick={() => setShowContactModal(true)}
                className="text-black hover:bg-BarnRed-900 hover:text-white block px-3 py-2 cursor-pointer rounded-md text-base font-medium"
              >
                Contact
              </a>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
