import React from "react";
import FeaturedProducts from "./FeaturedProducts";

function Home(props) {
  const { productsRef,
   } = props;

  return (
    <div className="">
      <img
        src="littleRedShed.jpeg"
        alt="hero"
        className=" h-96 md:h-full object-cover z-10 "
      />
      <div className="flex flex-col justify-center items-center">
        <div className=" w-4/5 md:w-2/3 justify-center text-center pt-12">
          <h1 className="justify-center text-center text-2xl pb-4 ">
            Words that mean something
          </h1>
          <p>
            Little Red Shed is a family-owned farm that provides local, fresh
            produce, chicken eggs, and goat's milk. We grow seasonal vegetables,
            fruits, and herbs using sustainable farming practices. Our chicken
            eggs and goat's milk are produced with the same attention to quality
            and sustainability. Little Red Shed is committed to providing the
            freshest local food possible and supporting the community.
          </p>
        </div>
        <FeaturedProducts
        productsRef={productsRef}
        />
      </div>
    </div>
  );
}

export default Home;
