import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

export default function ContactModal(props) {
  const { showContactModal, setShowContactModal } = props;

  function closeModal() {
    setShowContactModal(false);
  }

  function openModal() {
    setShowContactModal(true);
  }

  return (
    <>
      <Transition appear show={showContactModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Contact Form
                  </Dialog.Title>
                  <div className="mt-2 flex-col justify-center items-center text-center">
                    <form>
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="John Doe"
                            className="w-full sm:text-md border text-center border-gray-300 rounded-sm"
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            placeholder="JohnDoe@email.com"
                            className=" w-full sm:text-md outline-1 text-center border border-gray-300 rounded-sm"
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="message"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Message
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="message"
                            name="message"
                            rows="3"
                            className="shadow-sm p-2 block w-full sm:text-sm border border-gray-300 rounded-sm"
                          ></textarea>
                        </div>
                      </div>
                      <div className="mt-6 flex flex-col items-center">
                        <button
                          type="submit"
                          className="w-full mb-3 justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-BarnRed-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        >
                          Send
                        </button>
                      </div>
                    </form>
                    <button
                      type="button"
                      className="justify-center text-gray-500 hover:text-black w-20 text-center items-center hover:underline active:sacle-95"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
