import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ChartPieIcon, ShoppingBagIcon, ArchiveBoxIcon, UserIcon, DocumentTextIcon, CalendarDaysIcon  } from "@heroicons/react/20/solid";



const navigation = [
   { name: 'Dashboard', href: '/Dashboard', current: true, icon: ChartPieIcon },
   { name: 'Products', href: '/Dashboard/products', current: true, icon: ShoppingBagIcon },
   { name: 'Orders', href: '/Dashboard/orders', current: false, icon: ArchiveBoxIcon },
   { name: 'Users', href: '/Dashboard/users', current: false, icon: UserIcon },
   { name: 'Posts', href: '/Dashboard/posts', current: false, icon: DocumentTextIcon },
   { name: 'Calendar', href: '/Dashboard/calendar', current: false, icon: CalendarDaysIcon },
]





function Dashboard(props) {
   const { isSidebarOpen } = props;
    const location = useLocation();

   function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
 
   return (
     <div>
       {/* Rest of the Dashboard content */}
       
       {isSidebarOpen && (
         <aside
           id="admin-sidebar"
           className="fixed top-0 left-0 z-40 w-36 h-screen transition-transform"
           aria-label="Sidebar"
         >
           <div className="mt-16 h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
             <ul className="space-y-2 font-medium">
               {navigation.map((item) => (
                 <Link
                   key={item.name}
                   to={item.href}
                   className={classNames(
                      location.pathname === item.href
                        ? 'bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100'
                        : 'text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                   aria-current={item.current ? 'page' : undefined}
                 >
                   <item.icon className="w-5 h-5 mr-2" aria-hidden="true" />
                   {item.name}
                 </Link>
               ))}

             </ul>
           </div>
         </aside>
       )}
     </div>
   );
 }
 
 export default Dashboard;
 
