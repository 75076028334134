import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../DeleteModal";
import NewProductModal from "../NewProductModal";
import EditProductModal from "../EditProductModal";

function ProductList(props) {
  const { isUserAdmin, productsRef, toast } = props;

  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productIdToRemove, setProductIdToRemove] = useState(null);
  const [productToEdit, setProductToEdit] = useState(null);
  const [showNewProductModal, setShowNewProductModal] = useState(false);
  const [showEditProductModal, setShowEditProductModal] = useState(false);

  const navigate = useNavigate();
  /* check if user is admin, if not redirect to home page */
  if (!isUserAdmin) {
    navigate("/");
  }

  useEffect(() => {
    const unsubscribe = productsRef.onSnapshot((snapshot) => {
      const productList = snapshot.docs.map((doc) => ({
        id: doc.id,
        productId: doc.data().productId,
        name: doc.data().name,
        price: doc.data().price,
        imageSrc: doc.data().imageSrc,
        imageAlt: doc.data().imageAlt,
        pricePer: doc.data().pricePer,
        stock: doc.data().stock,
        description: doc.data().description,
        featured: doc.data().featured,
      }));
      setProducts(productList);
    });
  }, [productsRef, setProducts]);

  function handleProductEdit(selectedProduct) {
    setProductToEdit(selectedProduct);
    // Open the edit modal
    setShowEditProductModal(true);
  }

  function handleProductRemove(id) {
    // Set the product ID to be removed
    setProductIdToRemove(id);
    // Open the confirmation modal
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setProductIdToRemove(null);
  };

  const confirmRemoveProduct = async () => {
    if (productIdToRemove) {
      // Perform the removal logic
      try {
        await productsRef.doc(productIdToRemove).delete();

        toast.success("Product successfully removed.");
        // Log the product ID to be removed
        console.log("Remove product with ID:", productIdToRemove);
        // Close the modal
        closeModal();
      } catch (error) {
        console.error("Error removing document:", error);
        // Display error toast
        toast.error("An error occurred while removing the product.");
      }
    }
  };

  return (
    <div className="h-fit mb-44">
      {isUserAdmin ? (
        <div className="mt-24 flex flex-col  items-center">
            <div className="flex ml-6">
          <h1 className="text-3xl font-bold text-gray-800 ">Product List</h1>
          <button className="ml-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-500 hover:text-white hover:bg-gray-400 rounded-full cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={() => setShowNewProductModal(true)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
          </button>
          </div>
          <div className=" mt-12  relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 w-44 py-3">
                    Product name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    price
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Available
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {products.map((product) => (
                  <tr
                    key={product.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4  hover:underline hover:font-bold cursor-pointer font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                        <Link to={`/product/${product.id}`}>
                      {product.name}
                        </Link>
                    </th>
                    <td className="px-6 py-4">
                      {"$" + product.price + " " + product.pricePer}
                    </td>
                    <td className="px-6 py-4">{product.stock}</td>
                    <td className="flex items-center px-6 py-4 space-x-3">
                      <button
                        onClick={() => handleProductEdit(product)}
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleProductRemove(product.id)}
                        className="font-medium text-red-600 dark:text-red-500 hover:underline"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <h1> you dont have access</h1>
      )}
      <DeleteModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onConfirm={confirmRemoveProduct}
      />
      <NewProductModal
        showNewProductModal={showNewProductModal}
        setShowNewProductModal={setShowNewProductModal}
        toast={toast}
        productsRef={productsRef}
      />
        <EditProductModal
        productToEdit={productToEdit}
        setProductToEdit={setProductToEdit}
        showEditProductModal={showEditProductModal}
        setShowEditProductModal={setShowEditProductModal}
        toast={toast}
        productsRef={productsRef}
        />
    </div>
  );
}

export default ProductList;
