import React from 'react'

function Blog(props) {

    const {
        blogRef,
    } = props;



    blogRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.id, ' => ', doc.data().content);
        });
      }).catch((error) => {
        console.error(error);
      });

  return (
    <div className=''>
        <h1>Blog</h1>
    </div>
  )
}

export default Blog