import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Shop(props) {
  const {
    productsRef,
  } = props;


  const [products, setProducts] = useState([])

  useEffect (() => {
    const unsubscribe = productsRef.onSnapshot((snapshot) => {
      const productList = snapshot.docs.map((doc) => ({
        id: doc.id,
        productId: doc.data().productId,
        name: doc.data().name,
        price: doc.data().price,
        imageSrc: doc.data().imageSrc,
        imageAlt: doc.data().imageAlt,
        pricePer: doc.data().pricePer,
      }))
      setProducts(productList)
    })
      }, [productsRef, setProducts])





  
  return (
    <div className="bg-white">
      <h1 className=" w-full flex-col font-bold mt-10 text-3xl items-center text-center"> Our Products</h1>
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Products</h2>
        

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {products.map((product) => (
            <a key={product.id} href={`/product/${product.id}`} className="group">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
              <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
              <div className="flex ">
              <p className="mt-1 text-lg font-medium text-gray-900">{product.price}</p>
              <p className="mt-1 text-sm ml- pt-1 text-gray-500">{product.pricePer}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}